import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../hooks/useApi";
import { useAuth } from "../../contexts/AuthContext";
import { Checkbox } from "../ui/checkbox";
import { Button } from "../ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "../ui/card";
import { Label } from "../ui/label";
import { useToast } from "../ui/use-toast";

const Settings = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { apiCall } = useApi();
  const [visible_sections, setvisible_sections] = useState({});
  const { toast } = useToast();

  const fetchUserSettings = useCallback(async () => {
    if (!user || !user.id) return;
    try {
      const response = await apiCall("get", `/users/${user.id}/settings`);
      console.log("Fetched settings:", response.visible_sections);
      setvisible_sections(response.visible_sections || {});
    } catch (error) {
      console.error("Error fetching user settings:", error);
      toast({
        title: t("error_fetching_settings"),
        description: t("please_try_again"),
        variant: "destructive",
      });
    }
  }, [apiCall, user, t, toast]);

  useEffect(() => {
    fetchUserSettings();
  }, [fetchUserSettings]);

  const handleSectionToggle = (section) => {
    setvisible_sections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const saveSettings = async () => {
    try {
      await apiCall("put", `/users/${user.id}/settings`, { visible_sections });
      console.log("Settings saved:", visible_sections);
      toast({
        title: t("settings_saved_successfully"),
        description: t("your_changes_have_been_saved"),
      });
    } catch (error) {
      console.error("Error saving settings:", error);
      toast({
        title: t("error_saving_settings"),
        description: t("please_try_again"),
        variant: "destructive",
      });
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("settings_title")}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div>
            <h3 className="text-lg font-semibold mb-2">
              {t("visible_sections")}
            </h3>
            <div className="space-y-2">
              {["home", "apartments", "guides", "calendar"].map((section) => (
                <div key={section} className="flex items-center space-x-2">
                  <Checkbox
                    id={section}
                    checked={!!visible_sections[section]}
                    onCheckedChange={() => handleSectionToggle(section)}
                  />
                  <Label htmlFor={section}>{t(`section_${section}`)}</Label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </CardContent>
      <CardFooter>
        <Button onClick={saveSettings}>{t("save_settings")}</Button>
      </CardFooter>
    </Card>
  );
};

export default Settings;
