import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { Plus } from "lucide-react";
import ItemCard from "./ItemCard";
import ItemForm from "./ItemForm";
import { useApi } from "../../hooks/useApi";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";

const ItemList = ({
  items = [],
  isLoading,
  error,
  onRefetch,
  itemType,
  title,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const { t } = useTranslation();

  const { apiCall } = useApi();

  if (isLoading) {
    return <p>{t("common_loading")}</p>;
  }

  if (error) {
    return (
      <p className="text-red-500">
        {t("common_error_message")}: {error.message}
      </p>
    );
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setEditingItem(null);
  };

  const handleDelete = async (itemId) => {
    try {
      const data = await apiCall("delete", `/${itemType}/${itemId}`);
      console.log(data);
      onRefetch();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const dialogTitle = editingItem ? t("common_edit") : t("common_add");

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">{title}</h2>
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogTrigger asChild>
            <Button className="bg-indigo-600 hover:bg-indigo-700 text-white rounded-lg px-4 py-2 flex items-center">
              {t("common_add")}
              <Plus className="ml-2 h-4 w-4" />
            </Button>
          </DialogTrigger>
          <DialogContent
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            onClick={handleCloseDialog}
          >
            <div
              className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl h-auto max-h-[90vh] overflow-y-auto"
              onClick={(e) => e.stopPropagation()}
            >
              <DialogHeader>
                <DialogTitle>
                  {dialogTitle} {t(`${itemType}_title`)}
                </DialogTitle>
              </DialogHeader>
              <ItemForm
                item={editingItem}
                onSubmit={(formData) => {
                  console.log(formData);
                  handleCloseDialog();
                  onRefetch();
                }}
                onCancel={handleCloseDialog}
                itemType={itemType}
              />
            </div>
          </DialogContent>
        </Dialog>
      </div>
      {Array.isArray(items) && items.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {items.map((item) => (
            <ItemCard
              key={item.id}
              item={item}
              onEdit={() => {
                setEditingItem(item);
                setIsDialogOpen(true);
              }}
              onDelete={() => handleDelete(item.id)}
              itemType={itemType}
            />
          ))}
        </div>
      ) : (
        <p>{t("common_no_items")}</p>
      )}
    </div>
  );
};

export default ItemList;
