import React, { useState, useEffect, useRef } from "react";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Textarea } from "../ui/textarea";
import { Checkbox } from "../ui/checkbox";
import { Label } from "../ui/label";
import { DialogFooter } from "../ui/dialog";
import { ScrollArea } from "../ui/scroll-area";
import { Upload, X, Plus } from "lucide-react";
import { services, languages } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { useApi } from "../../hooks/useApi";
import { useToast } from "../ui/use-toast";

const ItemForm = ({ item, onSubmit, onCancel, itemType }) => {
  const [translations, setTranslations] = useState({
    it: { title: "", description: "" },
    en: { title: "", description: "" },
    fr: { title: "", description: "" },
    de: { title: "", description: "" },
    es: { title: "", description: "" },
  });
  const [selectedServices, setSelectedServices] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [icalUrls, setIcalUrls] = useState([]);
  const [maxGuests, setMaxGuests] = useState(1);
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("it");
  const fileInputRef = useRef(null);
  const { apiCall } = useApi();
  const { toast } = useToast();

  useEffect(() => {
    if (item) {
      setTranslations(
        item.translations || {
          it: { title: "", description: "" },
          en: { title: "", description: "" },
          fr: { title: "", description: "" },
          de: { title: "", description: "" },
          es: { title: "", description: "" },
        },
      );
      setSelectedServices(item.services || []);
      setPhotos(
        item.photos?.map((photo) => ({
          ...photo,
          isExisting: true,
          url: photo.url || (typeof photo === "string" ? photo : ""),
        })) || [],
      );
      setIcalUrls(item.ical_urls || []);
      setMaxGuests(item.max_guests || 1);
      setLocation(item.location || "");
      setAddress(item.address || "");
    }
  }, [item]);

  const handleTranslationChange = (lang, field, value) => {
    setTranslations((prev) => ({
      ...prev,
      [lang]: { ...prev[lang], [field]: value },
    }));
  };

  const handleServiceToggle = (serviceId) => {
    setSelectedServices((prev) =>
      prev.includes(serviceId)
        ? prev.filter((id) => id !== serviceId)
        : [...prev, serviceId],
    );
  };

  const handlePhotoUpload = (e) => {
    const files = e.target.files;
    if (files) {
      const newPhotos = Array.from(files).map((file) => ({
        file,
        preview: URL.createObjectURL(file),
        isNew: true,
        name: file.name,
      }));
      setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
    }
  };

  const handleRemovePhoto = (index) => {
    setPhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
  };

  const handleAddIcalUrl = () => {
    setIcalUrls((prev) => [...prev, ""]);
  };

  const handleRemoveIcalUrl = (index) => {
    setIcalUrls((prev) => prev.filter((_, i) => i !== index));
  };

  const handleIcalUrlChange = (index, value) => {
    setIcalUrls((prev) => {
      const newUrls = [...prev];
      newUrls[index] = value;
      return newUrls;
    });
  };

  const handleSubmit = async (e) => {
    console.log("ho cliccato submit");
    e.preventDefault();
    const formData = new FormData();

    formData.append("translations", JSON.stringify(translations));
    formData.append("services", JSON.stringify(selectedServices));

    // Handle existing photos
    const existingPhotos = photos
      .filter((photo) => photo.isExisting)
      .map((photo) => ({
        name: photo.name,
        url: photo.url,
      }));
    formData.append("existingPhotos", JSON.stringify(existingPhotos));

    // Handle new photos
    photos.forEach((photo) => {
      if (photo.isNew && photo.file) {
        formData.append("photos", photo.file, photo.file.name);
      }
    });

    console.log("le foto sono", photos);

    if (itemType === "apartment") {
      formData.append("ical_urls", JSON.stringify(icalUrls));
      formData.append("max_guests", maxGuests.toString());
      formData.append("location", location || "");
      formData.append("address", address || "");
    }

    try {
      let result;
      if (item) {
        result = await apiCall("put", `/apartments/${item.id}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } else {
        result = await apiCall("post", "/apartments", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
      toast({
        title: "Success",
        description: `${
          itemType.charAt(0).toUpperCase() + itemType.slice(1)
        } saved successfully`,
      });
      onSubmit(result);
    } catch (error) {
      console.error(`Error saving ${itemType}:`, error);
      toast({
        title: "Error",
        description:
          error.response?.data?.message ||
          `An error occurred while saving the ${itemType}`,
        variant: "destructive",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="flex border-b border-gray-200">
        {languages.map((lang) => (
          <button
            key={lang}
            type="button"
            className={`px-4 py-2 ${
              activeTab === lang
                ? "bg-white border-t border-x border-gray-200 -mb-px"
                : "bg-gray-100 text-gray-600"
            }`}
            onClick={() => setActiveTab(lang)}
          >
            {lang.toUpperCase()}
          </button>
        ))}
      </div>

      <div className="space-y-4">
        <div>
          <Label htmlFor={`title-${activeTab}`}>
            {t("item_form_title_label")} ({activeTab.toUpperCase()})
          </Label>
          <Input
            id={`title-${activeTab}`}
            value={translations[activeTab]?.title || ""}
            onChange={(e) =>
              handleTranslationChange(activeTab, "title", e.target.value)
            }
            placeholder={t("item_form_title_placeholder")}
            required={activeTab === "it"}
          />
        </div>

        <div>
          <Label htmlFor={`description-${activeTab}`}>
            {t("item_form_description_label")} ({activeTab.toUpperCase()})
          </Label>
          <Textarea
            id={`description-${activeTab}`}
            value={translations[activeTab]?.description || ""}
            onChange={(e) =>
              handleTranslationChange(activeTab, "description", e.target.value)
            }
            placeholder={t("item_form_description_placeholder")}
            required={activeTab === "it"}
            className="min-h-[100px] px-3 py-2"
          />
        </div>
      </div>

      {itemType === "apartment" && (
        <div className="space-y-4">
          <div>
            <Label htmlFor="address">{t("item_form_address_label")}</Label>
            <Input
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder={t("item_form_address_placeholder")}
              required
            />
          </div>
          <div>
            <Label htmlFor="location">{t("item_form_location_label")}</Label>
            <Input
              id="location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder={t("item_form_location_placeholder")}
              required
            />
          </div>
          <div>
            <Label htmlFor="maxGuests">{t("item_form_max_guests_label")}</Label>
            <Input
              id="maxGuests"
              type="number"
              min="1"
              value={maxGuests}
              onChange={(e) => setMaxGuests(e.target.value)}
              required
            />
          </div>
        </div>
      )}

      <div className="space-y-2">
        <Label>{t("item_form_services_label")}</Label>
        <div className="grid grid-cols-2 gap-2">
          {services.map((service) => (
            <div key={service.id} className="flex items-center space-x-2">
              <Checkbox
                id={service.id}
                checked={selectedServices.includes(service.id)}
                onCheckedChange={() => handleServiceToggle(service.id)}
              />
              <Label htmlFor={service.id}>{service.label}</Label>
            </div>
          ))}
        </div>
      </div>

      {itemType === "apartment" && (
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <Label className="mr-4">{t("item_form_ical_url_label")}</Label>
            <Button
              type="button"
              onClick={handleAddIcalUrl}
              variant="outline"
              size="sm"
            >
              <Plus className="h-4 w-4 mr-2" />
              {t("item_form_add_ical_url")}
            </Button>
          </div>
          {icalUrls.map((url, index) => (
            <div key={index} className="flex items-center space-x-2">
              <Input
                value={url}
                onChange={(e) => handleIcalUrlChange(index, e.target.value)}
                placeholder={t("item_form_ical_url_placeholder")}
              />
              <Button
                type="button"
                onClick={() => handleRemoveIcalUrl(index)}
                variant="outline"
                size="sm"
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
          ))}
        </div>
      )}

      <div>
        <Label>{t("item_form_photos_label")}</Label>
        <div
          className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer"
          onClick={() => fileInputRef.current?.click()}
        >
          <Upload className="mx-auto h-12 w-12 text-gray-400" />
          <p className="mt-2 text-sm text-gray-600">
            {t("item_form_upload_photo")}
          </p>
          <p className="text-xs text-gray-500">
            {t("item_form_drag_and_drop_photos")}
          </p>
          <input
            ref={fileInputRef}
            type="file"
            className="hidden"
            multiple
            accept="image/*"
            onChange={handlePhotoUpload}
          />
        </div>
      </div>

      {photos.length > 0 && (
        <ScrollArea className="h-[200px] w-full rounded-md border p-4">
          <div className="flex flex-wrap gap-2">
            {photos.map((photo, index) => (
              <div key={index} className="relative">
                <img
                  src={photo.preview || photo.url || ""}
                  alt={`Photo ${index + 1}`}
                  className="w-24 h-24 object-cover rounded"
                />

                <button
                  type="button"
                  onClick={() => handleRemovePhoto(index)}
                  className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
                >
                  <X className="h-4 w-4" />
                </button>
              </div>
            ))}
          </div>
        </ScrollArea>
      )}

      <DialogFooter>
        <Button type="button" variant="outline" onClick={onCancel}>
          {t("common_cancel")}
        </Button>
        <Button type="submit">{t("common_save")}</Button>
      </DialogFooter>
    </form>
  );
};

export default ItemForm;
