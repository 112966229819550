// components/dashboard/Sidebar.js
import React from "react";
import { Button } from "../ui/button";
import { ScrollArea } from "../ui/scroll-area";
import { Separator } from "../ui/separator";
import {
  Home,
  Building,
  BookOpen,
  Calendar,
  Settings,
  Users,
} from "lucide-react";

import { useTranslation } from "react-i18next";

const Sidebar = ({ activeSection, setActiveSection, isAdmin }) => {
  const { t } = useTranslation();

  const sections = [
    { id: "home", label: t("menu_home"), icon: Home },
    { id: "apartments", label: t("menu_apartments"), icon: Building },
    { id: "guides", label: t("menu_guides"), icon: BookOpen },
    { id: "calendar", label: t("menu_calendar"), icon: Calendar },
    { id: "settings", label: t("menu_settings"), icon: Settings },
  ];

  if (isAdmin) {
    sections.push({ id: "users", label: t("menu_users"), icon: Users });
  }

  return (
    <div className="w-64 bg-gray-100 border-r">
      <div className="p-4">
        <h1 className="text-2xl font-bold">{t("dashboard_title")}</h1>
      </div>
      <Separator />
      <ScrollArea className="h-[calc(100vh-5rem)]">
        <div className="p-4 space-y-2">
          {sections.map((section) => (
            <Button
              key={section.id}
              variant={activeSection === section.id ? "secondary" : "ghost"}
              className="w-full flex items-center justify-start"
              onClick={() => setActiveSection(section.id)}
            >
              <section.icon className="mr-2 h-4 w-4" />
              <span className="flex-grow text-left">{section.label}</span>
            </Button>
          ))}
        </div>
      </ScrollArea>
    </div>
  );
};

export default Sidebar;
