// src/components/Apartments.js
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Menu from "./Menu";
import ApartmentCard from "./ApartmentCard";
import { useDataPrefetch } from "../hooks/useDataPrefetch";
import { isProduction } from "../config";

const Apartments = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const userAlias = useMemo(() => {
    const hostname = window.location.hostname;
    const parts = hostname.split(".");
    console.log(parts);
    if (isProduction) {
      return parts.length > 2 ? parts[0] : null;
    } else {
      return parts.length > 1 ? parts[0] : null;
    }
  }, []);

  console.log("Alias del sito", userAlias);

  const {
    data: apartments,
    isLoading,
    error,
  } = useDataPrefetch("apartments", async (apiCall) => {
    if (userAlias) {
      return await apiCall("post", "/apartments/user-apartments", {
        userAlias,
      });
    }
  });

  const getTranslatedContent = (apartment, currentLanguage) => {
    if (apartment.translations && apartment.translations[currentLanguage]) {
      return {
        ...apartment,
        title: apartment.translations[currentLanguage].title || apartment.title,
        description:
          apartment.translations[currentLanguage].description ||
          apartment.description,
      };
    }
    return apartment;
  };

  const translatedApartments = useMemo(() => {
    return (
      apartments?.map((apartment) =>
        getTranslatedContent(apartment, i18n.language),
      ) || []
    );
  }, [apartments, i18n.language]);

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 to-green-100 p-4 sm:p-8">
      <Menu />
      <header className="mb-10 text-center pt-20">
        <h1 className="text-4xl font-bold text-blue-800 mb-2">
          {t("apartments_title")}
        </h1>
        <p className="text-xl text-gray-600">{t("apartments_description")}</p>
      </header>

      {isLoading ? (
        <div className="text-center text-xl text-gray-600">
          {t("apartments_loading")}
        </div>
      ) : error ? (
        <div className="text-center text-xl text-red-600">{error}</div>
      ) : translatedApartments.length === 0 ? (
        <div className="text-center text-xl text-gray-600">
          {t("no_apartments_message")}
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {translatedApartments.map((apartment) => (
            <ApartmentCard key={apartment.id} apartment={apartment} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Apartments;
