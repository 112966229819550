// client/src/hooks/useApi.js
import { useState, useCallback } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config";
import authService from "../services/authService";

export const useApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiCall = useCallback(async (method, endpoint, data = null) => {
    setIsLoading(true);
    setError(null);

    const headers = {
      //"Content-Type": "application/json",
      Authorization: `Bearer ${authService.getToken()}`,
    };

    try {
      const response = await axios({
        method,
        url: `${API_BASE_URL}${endpoint}`,
        data,
        headers,
      });
      setIsLoading(false);
      console.log(response.data);
      return response.data;
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 401) {
        try {
          await authService.refreshToken();
          // Retry the original request with the new token
          const retryResponse = await axios({
            method,
            url: `${API_BASE_URL}${endpoint}`,
            data,
            headers: {
              ...headers,
              Authorization: `Bearer ${authService.getToken()}`,
            },
          });
          setIsLoading(false);
          return retryResponse.data;
        } catch (refreshError) {
          authService.logout();
          setError("Session expired. Please log in again.");
          window.location.href = "/login"; // Redirect to login page
          throw new Error("Session expired");
        }
      } else {
        setError(err.response ? err.response.data.message : err.message);
        setIsLoading(false);
        throw err;
      }
    }
  }, []);

  return { apiCall, isLoading, error };
};
