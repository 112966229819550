// components/dashboard/Calendar.js
import React from "react";

const Calendar = () => {
  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Calendario Occupazione</h2>
      <p>Implementazione del calendario da completare.</p>
    </div>
  );
};

export default Calendar;
