import React, { useState, useEffect, useCallback } from "react";
import Sidebar from "./Sidebar";
import ApartmentList from "./ApartmentList";
import GuideList from "./GuideList";
import Calendar from "./Calendar";
import Settings from "./Settings";
import { useApi } from "../../hooks/useApi";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
import AdminUsersTable from "../AdminUsersTable";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [activeSection, setActiveSection] = useState("home");
  const { user, logout } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { apiCall, isLoading: isLoadingApi, error: apiError } = useApi();

  const [apartments, setApartments] = useState([]);
  const [guides, setGuides] = useState([]);

  const fetchApartments = useCallback(async () => {
    try {
      const data = await apiCall("get", "/apartments");
      console.log("appartamenti da api", data);
      setApartments(data);
    } catch (error) {
      console.error("Error fetching apartments:", error);
    }
  }, [apiCall]);

  const fetchGuides = useCallback(async () => {
    try {
      const data = await apiCall("get", "/guides");
      console.log("guide da api", data);
      setGuides(data);
    } catch (error) {
      console.error("Error fetching guides:", error);
    }
  }, [apiCall]);

  useEffect(() => {
    console.log("Dashboard - Current user:", user);
    fetchApartments();
    fetchGuides();
  }, [fetchApartments, fetchGuides, user]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const renderContent = () => {
    switch (activeSection) {
      case "home":
        return <h2 className="text-2xl font-bold">{t("dashboard_welcome")}</h2>;
      case "apartments":
        return (
          <ApartmentList
            apartments={apartments}
            isLoading={isLoadingApi}
            error={apiError}
            onRefetch={fetchApartments}
          />
        );
      case "guides":
        return (
          <GuideList
            guides={guides}
            isLoading={isLoadingApi}
            error={apiError}
            onRefetch={fetchGuides}
          />
        );
      case "calendar":
        return <Calendar />;
      case "settings":
        return <Settings />;
      case "users":
        return user.role === "admin" ? <AdminUsersTable /> : null;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <header className="bg-gray-800 text-white p-4 flex justify-between items-center">
        <h1 className="text-xl font-bold">{t("dashboard_title")}</h1>
        <Button
          onClick={handleLogout}
          variant="outline"
          className="text-white border-white hover:bg-gray-700"
        >
          {t("logout")}
        </Button>
      </header>
      <div className="flex flex-1 overflow-hidden">
        <Sidebar
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          isAdmin={user?.role === "admin"}
        />
        <main className="flex-1 overflow-auto">
          <div className="p-8">
            {isLoadingApi && <p>{t("loading")}</p>}
            {apiError && (
              <p className="text-red-500">
                {t("error_message")}: {apiError}
              </p>
            )}
            {!isLoadingApi && !apiError && renderContent()}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
