// client/src/services/authService.js
import axios from "axios";

const isProduction = process.env.NODE_ENV === "production";
export const API_BASE_URL = isProduction ? "/api" : "http://localhost:3001/api";
export const UPLOADS_URL = `${API_BASE_URL}/uploads`;

const authService = {
  async login(email, password) {
    const response = await axios.post(`${API_BASE_URL}/auth/login`, {
      email,
      password,
    });
    console.log(response.data);
    if (response.data.token) {
      localStorage.setItem("token", response.data.token);
    }
    return response.data;
  },

  logout() {
    localStorage.removeItem("token");
  },

  getToken() {
    return localStorage.getItem("token");
  },

  async refreshToken() {
    const token = this.getToken();
    if (!token) throw new Error("No token available");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/refresh-token`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
      }
      return response.data.token;
    } catch (error) {
      this.logout();
      throw error;
    }
  },

  async verifyToken() {
    const token = this.getToken();
    if (!token) throw new Error("No token available");

    try {
      const response = await axios.get(`${API_BASE_URL}/auth/verify`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data.user;
    } catch (error) {
      this.logout();
      throw error;
    }
  },
};

export default authService;
