// src/components/AdminUsers.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../hooks/useApi";
import Menu from "./Menu";
import { useTranslation } from "react-i18next";

const AdminUsers = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const { apiCall, isLoading, error } = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const data = await apiCall("get", "/admin/users");
        setUsers(data);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          navigate("/login");
        }
        console.error("Error fetching users:", err);
      }
    };

    loadUsers();
  }, [apiCall, navigate]);

  const toggleUserStatus = async (userId, currentStatus) => {
    try {
      const newStatus = currentStatus === "active" ? "inactive" : "active";
      await apiCall("put", `/admin/users/${userId}/status`, {
        status: newStatus,
      });
      setUsers(
        users.map((user) =>
          user.id === userId ? { ...user, status: newStatus } : user,
        ),
      );
    } catch (err) {
      console.error("Error updating user status:", err);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 to-green-100 p-4 sm:p-8">
      <Menu />
      <header className="mb-10 text-center pt-20">
        <h1 className="text-4xl font-bold text-blue-800 mb-2">
          {t("admin_users_title")}
        </h1>
      </header>

      {isLoading ? (
        <div className="text-center text-xl text-gray-600">{t("loading")}</div>
      ) : error ? (
        <div className="text-center text-xl text-red-600">{error}</div>
      ) : (
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-200 text-gray-700">
            <tr>
              <th className="py-3 px-4 text-left">{t("user_email")}</th>
              <th className="py-3 px-4 text-left">{t("user_role")}</th>
              <th className="py-3 px-4 text-left">{t("user_status")}</th>
              <th className="py-3 px-4 text-left">{t("actions")}</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr
                key={user.id}
                className="border-b border-gray-200 hover:bg-gray-100"
              >
                <td className="py-3 px-4">{user.email}</td>
                <td className="py-3 px-4">{user.role}</td>
                <td className="py-3 px-4">{user.status}</td>
                <td className="py-3 px-4">
                  <button
                    onClick={() => toggleUserStatus(user.id, user.status)}
                    className={`px-4 py-2 rounded ${
                      user.status === "active"
                        ? "bg-red-500 hover:bg-red-600"
                        : "bg-green-500 hover:bg-green-600"
                    } text-white`}
                  >
                    {user.status === "active" ? t("deactivate") : t("activate")}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminUsers;
