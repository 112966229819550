// src/components/GuideDetail.js

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Menu from "./Menu";
import { getGuideById } from "../utils/dataloader"; // Assicurati che questa funzione esista

const GuideDetail = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [guide, setGuide] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGuide = async () => {
      try {
        const fetchedGuide = await getGuideById(id, i18n.language);
        setGuide(fetchedGuide);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching guide:", err);
        setError(t("guide.fetchError"));
        setLoading(false);
      }
    };

    fetchGuide();
  }, [id, i18n.language, t]);

  if (loading) return <div>{t("guide.loading")}</div>;
  if (error) return <div>{error}</div>;
  if (!guide) return <div>{t("guide.notFound")}</div>;

  return (
    <div className="bg-gradient-to-b from-blue-50 to-white min-h-screen">
      <Menu />
      <header className="mb-10 text-center pt-16">
        <h1 className="text-4xl font-bold text-blue-800 mb-2">{guide.title}</h1>
        <p className="text-xl text-gray-600">{guide.description}</p>
      </header>

      <main className="max-w-6xl mx-auto">
        {guide.image && (
          <img
            src={guide.image}
            alt={guide.title}
            className="w-full h-96 object-cover mb-4 rounded-md"
          />
        )}
        {/* Add more content related to the guide */}
      </main>

      <footer className="mt-8 sm:mt-12 text-center text-gray-600">
        <p className="text-sm sm:text-base">{t("common_footer")}</p>
      </footer>
    </div>
  );
};

export default GuideDetail;
