// src/components/Guides.js
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import { UPLOADS_URL } from "../config";
import { useDataPrefetch } from "../hooks/useDataPrefetch";

const Guides = () => {
  const { t, i18n } = useTranslation();

  const {
    data: guides,
    isLoading,
    error,
  } = useDataPrefetch(
    "guides",
    async (apiCall) => await apiCall("get", "/guides"),
  );

  const getTranslatedContent = (guide, currentLanguage) => {
    if (guide.translations && guide.translations[currentLanguage]) {
      return {
        ...guide,
        title: guide.translations[currentLanguage].title || guide.title,
        description:
          guide.translations[currentLanguage].description || guide.description,
      };
    }
    return guide;
  };

  const translatedGuides = useMemo(() => {
    return (
      guides?.map((guide) => getTranslatedContent(guide, i18n.language)) || []
    );
  }, [guides, i18n.language]);

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 to-green-100 p-4 sm:p-8">
      <Menu />
      <header className="mb-10 text-center pt-20">
        <h1 className="text-4xl font-bold text-blue-800 mb-2">
          {t("guides_title")}
        </h1>
        <p className="text-xl text-gray-600">{t("guides_subtitle")}</p>
      </header>

      {isLoading ? (
        <div className="text-center text-xl text-gray-600">
          {t("guides.loading")}
        </div>
      ) : error ? (
        <div className="text-center text-xl text-red-600">{error}</div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {translatedGuides.map((guide) => (
            <Link key={guide.id} to={`/guides/${guide.id}`} className="block">
              <div className="bg-white rounded-lg overflow-hidden shadow-lg transition-transform duration-300 hover:scale-105">
                <img
                  src={
                    guide.photos && guide.photos.length > 0
                      ? `${UPLOADS_URL}${guide.photos[0].url}`
                      : "/placeholder-image.jpg"
                  }
                  alt={guide.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <h2 className="text-2xl font-bold mb-2 text-gray-800">
                    {guide.title}
                  </h2>
                  <p className="text-gray-600">{guide.description}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Guides;
